import { IMainRoute, IStop, IStopDetails } from '../Models/RoutePlannerModel';
import { IUser } from '../Models/UserModel';
import { EMAIL_SUBJECT_FALLBACK } from '../Other/constants';

export const generateRouteEmailSubject = (
  mainRouteData: IMainRoute,
  stopDetailData: IStopDetails | undefined,
  userData: IUser | null,
  stopData: IStop
) => {
  if (
    stopDetailData === null ||
    userData === null ||
    !userData?.email_subject
  ) {
    return EMAIL_SUBJECT_FALLBACK;
  }

  const emailSubjectContent = userData.email_subject;

  // Extract placeholders used in the email subject
  const placeholders = emailSubjectContent.match(/{[^}]+}/g) || [];

  // Define all possible parameters with their values
  const params = {
    company_name: userData?.company_name || '',
    origin_city: stopData?.origin.city || '',
    origin_state: stopData?.origin.state || '',
    destination_city: stopData?.destination?.city || '',
    destination_state: stopData?.destination?.state || '',
    equipment_type: stopDetailData?.equipment_type || '',
    total_price:
      mainRouteData?.rpm && mainRouteData?.mileage
        ? '$' +
          Math.round(mainRouteData.rpm * mainRouteData.mileage * 100) / 100
        : 'n/a',
    rate_per_mile:
      mainRouteData?.rpm !== undefined && mainRouteData.rpm !== null
        ? mainRouteData.rpm.toString()
        : '',
    pickup_time: stopDetailData?.pickup_dt || '',
    first_name: userData?.first_name || 'User Name Missing',
    last_name: userData?.last_name || 'Last Name Missing',
    email: userData?.email || 'None@gmail.com',
    phone_number: userData?.phone_number || '',
    mc_number: userData?.mc_number || 'N/A',
    dot_number: userData?.dot_number || 'N/A',
  };

  // Check if any of the used placeholders are empty
  for (let placeholder of placeholders) {
    const key = placeholder.slice(1, -1) as keyof typeof params; // Type assertion here
    if (params[key] === '') {
      return EMAIL_SUBJECT_FALLBACK;
    }
  }

  // If all used placeholders have data, proceed with replacements
  let updatedContent = emailSubjectContent;
  for (let placeholder of placeholders) {
    const key = placeholder.slice(1, -1) as keyof typeof params;
    updatedContent = updatedContent.replace(
      new RegExp(placeholder, 'g'),
      params[key]
    );
  }

  return updatedContent.trim();
};

export const generateRouteEmailBody = (
  mainRouteData: IMainRoute,
  stopDetailData: IStopDetails | undefined,
  userData: IUser | null,
  stopData: IStop
) => {
  if (
    stopDetailData === null ||
    userData === null ||
    !userData?.email_template
  ) {
    return EMAIL_SUBJECT_FALLBACK;
  }
  const emailContent = userData?.email_template || '';

  const firstName = userData?.first_name || '< your first name >';
  const lastName = userData?.last_name || '< your last name >';

  const userCompanyName = userData?.company_name || '< your company name >';
  const userEmail = userData?.email || 'your-email@gmail.com';
  const userPhoneNumber = userData?.phone_number || '< your phone number >';
  const userMcNumber = userData?.mc_number || 'N/A';
  const userDotNumber = userData?.dot_number || 'N/A';

  const originCity = stopData?.origin?.city || '';
  const originState = stopData?.origin?.state || '';
  const destinationCity = stopData?.destination?.city || '';
  const destinationState = stopData?.destination?.state || '';
  const equipmentType = stopDetailData?.equipment_type || '';
  const totalPrice =
    mainRouteData?.rpm && mainRouteData?.mileage
      ? '$' + Math.round(mainRouteData.rpm * mainRouteData.mileage * 100) / 100
      : 'n/a';
  const ratePerMile =
    mainRouteData?.rpm !== undefined && mainRouteData.rpm !== null
      ? mainRouteData.rpm.toString()
      : '';
  const pickupTime = stopDetailData?.pickup_dt || '';

  // Replace placeholders with actual data
  let updatedContent = emailContent
    .replace(/{origin_city}/g, originCity)
    .replace(/{origin_state}/g, originState)
    .replace(/{destination_city}/g, destinationCity)
    .replace(/{destination_state}/g, destinationState)
    .replace(/{equipment_type}/g, equipmentType)
    .replace(/{total_price}/g, totalPrice)
    .replace(/{rate_per_mile}/g, ratePerMile)
    .replace(/{pickup_time}/g, pickupTime)
    .replace(/{first_name}/g, firstName)
    .replace(/{last_name}/g, lastName)
    .replace(/{company_name}/g, userCompanyName)
    .replace(/{email}/g, userEmail)
    .replace(/{phone_number}/g, userPhoneNumber)
    .replace(/{mc_number}/g, userMcNumber)
    .replace(/{dot_number}/g, userDotNumber);

  return updatedContent.trim();
};

import { SEND_ROUTE_MESSAGE_ENDPOINT } from '../../App/Axios/endpoints';
import useAxios from '../useAxios/useAxios';

const useRouteApi = () => {
  const authAxios = useAxios(true); // Instance of axios with authentication

  // **Send Route Message**
  const sendRouteMessage = async (text: string) => {
    try {
      const url = SEND_ROUTE_MESSAGE_ENDPOINT;
      const response = await authAxios.post(url, {
        prompt: text,
      });
      return response;
    } catch (e: any) {
      console.log('error at route api sendRouteMessage call', e);
      return e.response;
    }
  };

  return {
    sendRouteMessage,
  };
};

export default useRouteApi;

const companyAbbreviations: {
  [index: string]: string;
  inc: 'INC';
  ltd: 'LTD';
  llc: 'LLC';
  corp: 'CORP';
  co: 'CO';
  plc: 'PLC';
  gmbh: 'GMBH';
  ag: 'AG';
  sa: 'SA';
  sas: 'SAS';
  sarl: 'SARL';
  pty: 'PTY';
  spa: 'SPA';
  srl: 'SRL';
  bv: 'BV';
  nv: 'NV';
  pls: 'PLS';
} = {
  inc: 'INC',
  ltd: 'LTD',
  llc: 'LLC',
  corp: 'CORP',
  co: 'CO',
  plc: 'PLC',
  gmbh: 'GMBH',
  ag: 'AG',
  sa: 'SA',
  sas: 'SAS',
  sarl: 'SARL',
  pty: 'PTY',
  spa: 'SPA',
  srl: 'SRL',
  bv: 'BV',
  nv: 'NV',
  pls: 'PLS',
};

export const normalizeString = (input: any): string => {
  if (input === undefined || input === null) {
    return ''; // or some default value or error message like 'N/A'
  }

  if (typeof input !== 'string') {
    return input.toString(); // Convert to string if not already a string
  }

  // Convert to lowercase, then handle abbreviations and capitalize words
  return input
    .toLowerCase()
    .replace(/[^a-z0-9\s:\/\?\.@]/g, '_') // Keep common URL characters, replace others with underscore
    .split(' ')
    .map((word) => {
      // Check if the word matches any company abbreviation
      if (companyAbbreviations[word]) {
        return companyAbbreviations[word];
      } else {
        // For words not matching abbreviations, capitalize the first letter
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
};

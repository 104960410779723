import styled from 'styled-components';
import MuiModal from '@mui/material/Modal';
import { Close } from '@styled-icons/material';
import { useState } from 'react';
import { useFeedbackMutation } from '../../Hooks/useApi/useMutations/useFeedbackMutation';
import { CustomCircularLoading } from '../CustomCircularLoading/CustomCircularLoading';

interface FeedBackModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentPromptText: string;
  setFeedbackGiven: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedBackModal: React.FC<FeedBackModalProps> = ({
  isOpen,
  setIsOpen,
  currentPromptText,
  setFeedbackGiven,
}) => {
  const { sendFeedbackFn, isLoadingFeedback } = useFeedbackMutation();
  const [feedbackText, setFeedbackText] = useState<string>('');

  const handleSubmitFeedback = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendFeedbackFn({
      prompt: currentPromptText,
      isPositive: false,
      comment: feedbackText,
    });
    setIsOpen(false);
    setFeedbackText('');
    setFeedbackGiven(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setFeedbackText('');
  };
  return (
    <MuiModal open={isOpen} closeAfterTransition>
      <Backdrop onClick={handleClose}>
        <FormContainer
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmitFeedback}
        >
          <ModalHeader>
            <ModalTitle>What went wrong?</ModalTitle>
            <CloseIcon size={35} onClick={handleClose} />
          </ModalHeader>
          <FormBody>
            <SubtitleContainer>
              <Label>Your help is greatly appreciated!</Label>
            </SubtitleContainer>
            <TextArea
              placeholder="Please enter your feedback..."
              onChange={(e) => {
                setFeedbackText(e.target.value);
              }}
              value={feedbackText}
            />
            {!isLoadingFeedback ? (
              <SubmitButton disabled={feedbackText.trim() === ''} type="submit">
                Submit
              </SubmitButton>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CustomCircularLoading size={30} marginTop={0} />
              </div>
            )}
          </FormBody>
        </FormContainer>
      </Backdrop>
    </MuiModal>
  );
};

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
`;

const FormContainer = styled.form`
  width: 400px;
  border-radius: 12px;
  padding: 20px;
  background-color: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.settingsBorderOutlineColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media only screen and (max-width: 440px) {
    /* Styles for screens less than 440px  */
    width: 350px;
  }
  @media only screen and (max-width: 370px) {
    /* Styles for screens less than 370px  */
    width: 0px;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ModalTitle = styled.h2`
  color: ${({ theme }) => theme.title};
  font-size: 25px;
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.alternativeWhite};
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const SubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.detailsText};
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const TextArea = styled.textarea`
  overflow: auto;
  min-width: 100%;
  resize: vertical;
  min-height: 100px;
  max-height: 400px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  border: none;
  padding: 0.5rem;
  border-radius: 10px;

  &:focus {
    outline: 0;
  }
  margin-bottom: 18px;
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  padding: 10px;
  margin-left: 2px;
  width: 150px;
  background-color: ${({ theme, disabled }) =>
    disabled ? 'gray' : theme.schedulerButtonBg};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.schedulerHoverBg};
  }
`;

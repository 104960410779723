import { ILoad } from '../Models/LoadModel';
import { IUser } from '../Models/UserModel';
import {
  EMAIL_TEMPLATE_FALLBACK,
  EMAIL_SUBJECT_FALLBACK,
} from '../Other/constants';
import { formatDateTime } from './datetimeUtil';

export const generateEmailSubject = (
  load: ILoad | null,
  userData: IUser | null
) => {
  if (load === null || userData === null || !userData?.email_subject) {
    return EMAIL_SUBJECT_FALLBACK;
  }

  const emailSubjectContent = userData.email_subject;

  // Extract placeholders used in the email subject
  const placeholders = emailSubjectContent.match(/{[^}]+}/g) || [];

  // Define all possible parameters with their values
  const params = {
    company_name: userData?.company_name || '',
    origin_city: load?.origin_city || '',
    origin_state: load?.origin_state || '',
    destination_city: load?.destination_city || '',
    destination_state: load?.destination_state || '',
    equipment_type: load?.equipment_type || '',
    total_price:
      load?.total_price !== undefined && load?.total_price !== null
        ? `$${load.total_price}`
        : '$0',
    rate_per_mile:
      load?.rate_per_mile !== undefined && load?.rate_per_mile !== null
        ? `$${load.rate_per_mile}`
        : '$0',
    pickup_time: load?.pickup ? formatDateTime(load.pickup) : '',
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || 'None@gmail.com',
    phone_number: userData?.phone_number || '',
    mc_number: userData?.mc_number || 'N/A',
    dot_number: userData?.dot_number || 'N/A',
  };

  // Check if any of the used placeholders are empty
  for (let placeholder of placeholders) {
    const key = placeholder.slice(1, -1) as keyof typeof params;
    if (params[key] === '') {
      return EMAIL_SUBJECT_FALLBACK;
    }
  }

  // If all used placeholders have data, proceed with replacements
  let updatedContent = emailSubjectContent;
  for (let placeholder of placeholders) {
    const key = placeholder.slice(1, -1) as keyof typeof params;
    updatedContent = updatedContent.replace(
      new RegExp(placeholder, 'g'),
      params[key]
    );
  }

  return updatedContent.trim();
};

export const generateEmailBody = (
  load: ILoad | null,
  userData: IUser | null
) => {
  if (load === null || userData === null) return EMAIL_TEMPLATE_FALLBACK;

  const emailContent = userData?.email_template || '';

  const firstName = userData?.first_name || '';
  const lastName = userData?.last_name || '';

  const companyName = userData?.company_name || '';
  const email = userData?.email || 'None@gmail.com';
  const phoneNumber = userData?.phone_number || '';
  const mcNumber = userData?.mc_number || 'N/A';
  const dotNumber = userData?.dot_number || 'N/A';

  const originCity = load.origin_city || '';
  const originState = load.origin_state || '';
  const destinationCity = load.destination_city || '';
  const destinationState = load.destination_state || '';
  const equipmentType = load.equipment_type || '';
  const totalPrice =
    load.total_price !== undefined && load.total_price !== null
      ? `$${load.total_price}`
      : '$0';
  const ratePerMile =
    load.rate_per_mile !== undefined && load.rate_per_mile !== null
      ? `$${load.rate_per_mile}`
      : '$0';
  const pickupTime = formatDateTime(load.pickup!!);

  // Replace placeholders with actual data
  let updatedContent = emailContent
    .replace(/{origin_city}/g, originCity)
    .replace(/{origin_state}/g, originState)
    .replace(/{destination_city}/g, destinationCity)
    .replace(/{destination_state}/g, destinationState)
    .replace(/{equipment_type}/g, equipmentType)
    .replace(/{total_price}/g, totalPrice)
    .replace(/{rate_per_mile}/g, ratePerMile)
    .replace(/{pickup_time}/g, pickupTime)
    .replace(/{first_name}/g, firstName)
    .replace(/{last_name}/g, lastName)
    .replace(/{company_name}/g, companyName)
    .replace(/{email}/g, email)
    .replace(/{phone_number}/g, phoneNumber)
    .replace(/{mc_number}/g, mcNumber)
    .replace(/{dot_number}/g, dotNumber);

  return updatedContent.trim();
};

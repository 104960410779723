import { useMediaQuery } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import { Title } from '../../App/Styles/Typography.styled';

interface RouteGreetingsProps {}

const RouteGreetings: React.FC<RouteGreetingsProps> = () => {
  const lessThan768px = useMediaQuery('(max-width:768px)');
  return (
    <>
      <Fade
        triggerOnce
        delay={150}
        style={{ gridRow: `${lessThan768px ? '2' : '2'}`, gridGap: '1rem' }}
      >
        <GreetingTextContainer>
          <GreetingsHeader
            style={{ transform: 'translateY(-80px)', fontSize: '25px' }}
          >
            <RouteTitle>{`I am your AI Route planner`}</RouteTitle>
            <RouteSubtitle>{`Tell me where you want to start your trip and I will build an optimal route for you`}</RouteSubtitle>
          </GreetingsHeader>
        </GreetingTextContainer>
      </Fade>
    </>
  );
};
export default RouteGreetings;

export const GreetingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  // @media (max-width: 768px) {
  //   grid-row: 1;
  //   height: 100%;
  // }
  // // border: 2px solid blue;
`;

const GreetingsHeader = styled.div`
  margin: 10px 0;
  font-size: 25px;
`;

const RouteTitle = styled(Title)`
  font-size: 25px;
`;

const RouteSubtitle = styled(Title)`
  margin-top: 10px;
  font-size: 22px;
`;

import useAxios from '../useAxios/useAxios';
import { LOGIN_ENDPOINT, USER_INFO_ENDPOINT } from '../../App/Axios/endpoints';
import { IUser, IUserProfileUpdate } from '../../Models/UserModel';

import { useMutation, useQuery, useQueryClient } from 'react-query';

const useAuthenticationApi = () => {
  // axios hook
  const axios = useAxios();
  const authAxios = useAxios(true);

  // ** Login to django server **
  const loginApi = async (email: string, password: string) => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      const response = await axios.post(LOGIN_ENDPOINT, formData);
      if (response.status === 200 || response.status === 201) {
        return response;
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  // ** Fetch all UserProfile data **
  const getUserProfile = async () => {
    try {
      const response = await authAxios.get(USER_INFO_ENDPOINT);
      if (response.status === 200 || response.status === 201) {
        return response.data.data as IUser;
      }
      console.log('Unexpected response status:', response.status);
      return null;
    } catch (e) {
      console.log('Error fetching userProfile:', e);
      return null;
    }
  };

  // ** Update all UserProfile data **
  const patchUserProfile = async (payload: IUserProfileUpdate) => {
    try {
      const response = await authAxios.patch(USER_INFO_ENDPOINT, payload);
      if (response.status === 200 || response.status === 204) {
        console.log('User profile updated successfully:', response.data);
        return response.data;
      }
      console.log('Unexpected response status:', response.status);
      return null;
    } catch (e) {
      console.log('Error patching userProfile:', e);
      return null;
    }
  };

  return { loginApi, getUserProfile, patchUserProfile };
};

export default useAuthenticationApi;

export const useGetUserProfile = () => {
  const { getUserProfile } = useAuthenticationApi();

  const {
    data: userProfileData,
    isSuccess: userProfileIsSuccess,
    isLoading: userProfileDataIsLoading,
    error: userProfileError,
  } = useQuery({
    queryKey: ['user data'],
    queryFn: getUserProfile,
    onError: (err: Error) => {
      console.log(err.message || 'Failed getting prompts');
    },
  });

  return {
    userProfileData,
    userProfileIsSuccess,
    userProfileDataIsLoading,
    userProfileError,
  };
};

export const usePatchUserProfile = () => {
  const { patchUserProfile } = useAuthenticationApi();
  const queryClient = useQueryClient();

  const {
    mutate: updateUserProfile,
    isLoading: isLoadingProfileUpdate,
    isError: isUpdateError,
    isSuccess: isUpdateSuccessful,
    error: updateError,
  } = useMutation({
    mutationFn: patchUserProfile,
    onSuccess: (updatedData) => {
      console.log('User profile updated successfully', updatedData);
      // Invalidate and refetch the user profile to reflect the updated data
      queryClient.invalidateQueries(['user data']);
    },
    onError: (error: Error) => {
      console.error('Error updating user profile:', error.message || error);
    },
  });

  return {
    updateUserProfile,
    isLoadingProfileUpdate,
    isUpdateError,
    isUpdateSuccessful,
    updateError,
  };
};

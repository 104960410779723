import { HelpBoxType } from '../../../App/Types/helpBox';
import MessageRecycler from '../../../Components/Recycler/MessageRecycler';
import { MessageItem } from '../../../Models/LoadModel';
import GreetingSection from './GreetingsSection';

interface ChatPageContentProps {
  updatedMessages: MessageItem[];
  handleTruckTypeSelect: (truckType: string) => void;
  onSendMessageClickListener: (query?: string) => Promise<void>;
  helpList: HelpBoxType[];
  onHelpBoxClickListener: (query: string) => Promise<void>;
  feedbackGiven: boolean;
  setFeedbackGiven: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatPageContent: React.FC<ChatPageContentProps> = ({
  updatedMessages,
  handleTruckTypeSelect,
  onSendMessageClickListener,
  helpList,
  onHelpBoxClickListener,
  feedbackGiven,
  setFeedbackGiven,
}: ChatPageContentProps) => {
  return updatedMessages.length ? (
    <MessageRecycler
      messages={updatedMessages}
      handleSelect={handleTruckTypeSelect}
      sendNewMessage={onSendMessageClickListener}
      feedbackGiven={feedbackGiven}
      setFeedbackGiven={setFeedbackGiven}
    />
  ) : (
    <GreetingSection
      helpList={helpList}
      onHelpBoxClickListener={onHelpBoxClickListener}
    />
  );
};

export default ChatPageContent;

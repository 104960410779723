import { useMutation, useQueryClient } from 'react-query';
import useChatApi from '../useChatApi';

export const useFeedbackMutation = () => {
  const { postFeedback } = useChatApi(); // Extract postFeedback from your chat API hook
  const queryClient = useQueryClient();

  const { mutate: sendFeedbackFn, isLoading: isLoadingFeedback } = useMutation({
    mutationFn: ({
      prompt,
      isPositive,
      comment,
    }: {
      prompt: string;
      isPositive: boolean;
      comment?: string; // Optional, with default value handled in postFeedback
    }) => postFeedback(prompt, isPositive, comment),
    onSuccess: (data) => {
      if (data) {
        console.log('Feedback sent successfully', data);
        // Optionally invalidate a query if feedback affects cached data
        queryClient.invalidateQueries(['feedback']); // Adjust the query key as needed
      }
    },
    onError: (error: Error) => {
      console.error('Error sending feedback:', error.message || error);
    },
  });

  return {
    sendFeedbackFn,
    isLoadingFeedback,
  };
};

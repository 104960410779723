import {
  KeyboardEvent,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  getCachedMessages,
  setCachedSessionStorageMessages,
} from '../../../Utils/messagesSessionStorage';
import { Container } from './ChatPage.styled';
import useChatApi from '../../../Hooks/useApi/useChatApi';
import { HELP_TEXT_LIST } from '../../../Other/constants';
import { MultipleMessagesData } from '../../../Models/BaseModel';
import { getRandomElements } from '../../../Utils/collectionUtils';
import { HelpBoxType } from '../../../App/Types/helpBox';
import { AuthenticationContext } from '../../../Context/Auth/AuthContext';
import { PluginContext } from '../../../Context/Plugin/PluginContext';
import { ChatPageInput } from './ChatPageInput';
import {
  addMessage,
  handleMultipleDataError,
  handleNoProviders,
  handleNoResponse,
  handleUnexpectedResultStructure,
} from './ChatPageHelpers';
import { MuiChatPageAlert } from './MuiChatPageAlert';
import ChatPageContent from './ChatPageContent';
import { MessageItem } from '../../../Models/LoadModel';

//* The ChatPage component handles the main dialogue within Client and AI *//
//* It returns either a "Greetings" section, or a "MessageRecycler" section*//
//* The MessageRecycler stores and handles all user/ai messages//

const ChatPage = () => {
  // State & React Hooks
  const navigate = useNavigate();
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<MessageItem[]>(
    () => getCachedMessages() || []
  );
  const [responseCompleted, setResponseCompleted] = useState<boolean>(false);
  const [isProgress, setIsProgress] = useState(false);
  const [helpList] = useState<HelpBoxType[]>(
    getRandomElements(HELP_TEXT_LIST, 4)
  );
  const [scheduledPromptMissingProviders, setScheduledPromptMissingProviders] =
    useState<string>('');
  const [hasFetchedScheduledPrompt, setHasFetchedScheduledPrompt] =
    useState<boolean>(false);
  const [feedbackGiven, setFeedbackGiven] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  const userMessageIndex = useRef<number>(-1);
  const currentUserMessage = useRef<string>('');
  // Context Hooks
  const authContext = useContext(AuthenticationContext);
  const pluginContext = useContext(PluginContext);
  // API Hooks
  const { sendMessage } = useChatApi();

  // Collect all the non-empty user messages
  const userMessages = messages
    .filter((m) => m.isUser && m.title)
    .map((m) => m.title!)
    .reverse();

  const scheduledPromptSession = sessionStorage.getItem('scheduled_prompt');

  // ** Removes scheduled prompt URL on load ** //
  useEffect(() => {
    searchParams.delete('scheduled_prompt');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  /* Update the input ref and state */
  const updateInput = (query: string) => {
    if (inputRef.current) inputRef.current.value = query;
    setInputText(query);
  };

  /* Cycle up through the history */
  const handleInputUp = () => {
    if (userMessageIndex.current < 0) {
      currentUserMessage.current = inputText;
    }
    if (userMessageIndex.current < userMessages.length - 1) {
      userMessageIndex.current++;
      updateInput(userMessages[userMessageIndex.current]);
    }
  };

  /* Cycle down through the history */
  const handleInputDown = () => {
    if (userMessageIndex.current === 0) {
      updateInput(currentUserMessage.current);
      userMessageIndex.current--;
    } else if (userMessageIndex.current >= 0) {
      userMessageIndex.current--;
      updateInput(userMessages[userMessageIndex.current]);
    }
  };

  /* Cycle through chat history or submit query */
  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await onSendMessageClickListener();
    } else if (event.key === 'ArrowUp') {
      handleInputUp();
    } else if (event.key === 'ArrowDown') handleInputDown();
  };

  // ** Main action handler, sends sendMessage(...) async function onClick or Enter ** //
  const onSendMessageClickListener = useCallback(
    async (query?: string) => {
      const textQuery = query ? query : inputText;
      const boards = [];

      if (isProgress) return;
      if (textQuery === '') return;
      if (inputRef.current !== null) inputRef.current!.value = '';

      // Redirect to auth if not found
      const tokens = authContext?.tokens;
      if (tokens === null) {
        console.log('token:', tokens);
        return navigate('/auth');
      }
      addMessage(setMessages, { isUser: true, title: textQuery });

      // Notify user no providers are enabled
      if (
        !pluginContext!.LB123Plugin &&
        !pluginContext!.truckstopPlugin &&
        !pluginContext!.manifoldPlugin
      ) {
        handleNoProviders(setMessages);
        setResponseCompleted(true); // Set response completed
        setFeedbackGiven(false);
        return;
      }

      if (pluginContext!.LB123Plugin) boards.push('123LoadBoard');
      if (pluginContext!.truckstopPlugin) boards.push('truckstop');
      if (pluginContext!.manifoldPlugin) boards.push('manifold');

      setIsProgress(true);
      setInputText('');
      const response = await sendMessage(textQuery, boards);

      //* extra error handling *//
      if (!response || response.status !== 200 || !response.data) {
        handleNoResponse(setMessages, response);
        setIsProgress(false);
        setResponseCompleted(true);
        setFeedbackGiven(false);
        return;
      }

      const multipleMessagesData = response.data as MultipleMessagesData;

      //* multipleMessagesData error handling *//
      if (
        !multipleMessagesData ||
        !multipleMessagesData.data ||
        !multipleMessagesData.data.results
      ) {
        handleMultipleDataError(setMessages, response.data);
        setIsProgress(false);
        setResponseCompleted(true);
        setFeedbackGiven(false);
        return;
      }

      if (response.status === 200 || response.status === 201) {
        // Summary of resultsArray: Array with multiple results, each result object is treated by the MessageRecycler.tsx
        // as a single message prompt or message. Regardless on whether the sendMessage response has 1 or multiple results.
        const resultsArray = multipleMessagesData.data.results;
        resultsArray.forEach((result) => {
          //* extra error handling *//
          if (!result || !result.user_query || result.loads === undefined) {
            handleUnexpectedResultStructure(setMessages, result);
            return; // Skip to the next result
          }
          const dynamicText = result.relaxed_message
            ? result.relaxed_message
            : result.text;

          // FOR SINGLE RESULTS WITHOUT LOADS
          if (result.loads.length === 0) {
            if (result.text.startsWith('Unfortunately')) {
              addMessage(setMessages, {
                isUser: false,
                title: dynamicText,
                userPrompt: result.user_query.trim(),
                promptTruckType: false,
                overallPromptNum: resultsArray.length,
              });
            }
            if (
              result.text.startsWith('There were no results for your query.')
            ) {
              addMessage(setMessages, {
                isUser: false,
                title: dynamicText,
                userPrompt: result.user_query.trim(),
                promptTruckType: false,
                overallPromptNum: resultsArray.length,
              });
            }
            if (result.text.startsWith('Please enter the')) {
              addMessage(setMessages, {
                isUser: false,
                title: dynamicText,
                userPrompt: result.user_query.trim(),
                promptTruckType: resultsArray.length > 1 ? false : true, // logic for helpboxes based on # of results
                overallPromptNum: resultsArray.length,
              });
            }
            // SINGLE current result, just with loads present to form a table
          } else if (result.loads.length > 0) {
            addMessage(setMessages, {
              isUser: false,
              title: dynamicText,
              userPrompt: result.user_query.trim(),
              loadList: result.loads,
              overallPromptNum: resultsArray.length,
              isRelaxed: !!result.relaxed_message,
            });
          }
        });
      } else if (response.status === 400 || response.status === 404) {
        // Handle 400 errors
        addMessage(setMessages, {
          isUser: false,
          title: response!.data.data.detail,
          promptTruckType:
            /\bequipment\b\s+.*?\btype\b|\btype\b\s+.*?\bequipment\b/gi.test(
              response!.data.data.detail
            ),
        });
      } else {
        // Something unexpectedly went wrong
        addMessage(setMessages, {
          isUser: false,
          title: 'Something went wrong processing your query.',
        });
        if (pluginContext!.truckstopPlugin) {
          pluginContext!.setTruckstopPlugin(false);
        }
        navigate('/error'); // Redirect to error page
      }
      setIsProgress(false);
      setResponseCompleted(true);
      setFeedbackGiven(false);
    },
    [inputText, isProgress, authContext, navigate, pluginContext, sendMessage]
  );

  const onHelpBoxClickListener = async (query: string) => {
    if (inputRef.current !== null) {
      inputRef.current.value = query;
    }
    setInputText(() => query);
    await onSendMessageClickListener(query);
  };

  // Handle selection of a trucktype from the prompt
  const handleTruckTypeSelect = useCallback(
    (truckType: string) => {
      if (inputRef.current !== null) {
        const text = `Required equipment type is ${truckType}`;
        inputRef.current.value = text;
        setInputText(text);
        inputRef.current.focus(); // Focus on input after a selection
      }
    },
    [setInputText]
  );

  useEffect(() => {
    if (responseCompleted && inputRef.current) {
      inputRef.current.focus(); // Focus on the input element after response is completed
      setResponseCompleted(false); // Reset the response completed state
    }
  }, [responseCompleted]);

  const isConnectedToProviders =
    pluginContext?.truckstopPlugin ||
    pluginContext!.LB123Plugin ||
    pluginContext!.manifoldPlugin;

  // ** Ensure that the effect only runs when the truckstopPlugin becomes enabled  ** //
  useEffect(() => {
    if (
      scheduledPromptSession &&
      isConnectedToProviders &&
      !hasFetchedScheduledPrompt
    ) {
      onSendMessageClickListener(scheduledPromptSession);
      setHasFetchedScheduledPrompt(true); // to avoid looping the api call
      // Remove the scheduled_prompt from the session
      sessionStorage.removeItem('scheduled_prompt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasFetchedScheduledPrompt,
    isConnectedToProviders,
    scheduledPromptSession,
  ]);

  // ** Set Missing Provider Error Alert on Scheduled  ** //
  useEffect(() => {
    if (isConnectedToProviders === false && scheduledPromptSession) {
      const timeout = setTimeout(() => {
        setScheduledPromptMissingProviders(
          'Connect to a provider for scheduled prompts'
        );
      }, 800);
      return () => clearTimeout(timeout);
    } else {
      setScheduledPromptMissingProviders('');
    }
  }, [isConnectedToProviders, scheduledPromptSession]);

  // ** Set cached data if existing  ** //
  useEffect(() => {
    if (messages.length > 0) {
      setCachedSessionStorageMessages(messages);
    }
  }, [messages]);

  const updatedMessages =
    messages.length > 0 ? messages : getCachedMessages() || [];

  return (
    <Container>
      <MuiChatPageAlert
        scheduledPromptMissingProviders={scheduledPromptMissingProviders}
        setScheduledPromptMissingProviders={setScheduledPromptMissingProviders}
      />
      {/* ChatPageContent renders either Greetings or MessageRecycler component based on updatedMessages.length */}
      <ChatPageContent
        updatedMessages={updatedMessages}
        handleTruckTypeSelect={handleTruckTypeSelect}
        onSendMessageClickListener={onSendMessageClickListener}
        helpList={helpList}
        onHelpBoxClickListener={onHelpBoxClickListener}
        feedbackGiven={feedbackGiven}
        setFeedbackGiven={setFeedbackGiven}
      />
      <ChatPageInput
        inputRef={inputRef}
        handleKeyDown={handleKeyDown}
        inputText={inputText}
        setInputText={setInputText}
        isProgress={isProgress}
        onSendMessageClickListener={onSendMessageClickListener}
        isMessages={updatedMessages.length > 0}
      />
    </Container>
  );
};

export default ChatPage;

import { IRouteMessageItem } from '../../Models/RoutePlannerModel';
import RouteGreetings from './RouteGreetings';
import RouteMessageRecycler from './RouteMessageRecycler';

interface RoutePlannerContentProps {
  routeMessages: IRouteMessageItem[];
  shouldScrollDown: boolean;
  setShouldScrollDown: React.Dispatch<React.SetStateAction<boolean>>;
  containerRef: React.RefObject<HTMLDivElement>;
}

const RoutePlannerContent: React.FC<RoutePlannerContentProps> = ({
  routeMessages,
  shouldScrollDown,
  setShouldScrollDown,
  containerRef,
}) => {
  const messageLength = routeMessages.length;
  return !messageLength ? (
    <RouteGreetings />
  ) : (
    <RouteMessageRecycler
      routeMessages={routeMessages}
      shouldScrollDown={shouldScrollDown}
      setShouldScrollDown={setShouldScrollDown}
      containerRef={containerRef}
    />
  );
};

export default RoutePlannerContent;

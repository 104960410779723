import { useRef, KeyboardEvent, useState, useContext } from 'react';
import styled from 'styled-components';

import RoutePlannerContent from './RoutePlannerContent';
import RoutePlannerInput from './RouterPlannerInput';
import { H1Title } from '../../App/Styles/Typography.styled';
import { sizes } from '../../Utils/screenUtil';
import {
  IRouteMessageItem,
  IRoutePlannerResponse,
} from '../../Models/RoutePlannerModel';
// import { dummyRPMessageItem } from './dummyRouteMessages';
import useRouteApi from '../../Hooks/useApi/useRouteApi';
import { AuthenticationContext } from '../../Context/Auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { addRouteMessage, handleNoRouteResponse } from './RoutePlannerHelpers';

const RoutePlannerPage = () => {
  // API Hook
  const { sendRouteMessage } = useRouteApi();
  // State Hooks
  const [routeMessages, setRouteMessages] = useState<IRouteMessageItem[]>([]);
  const [routeInputText, setRouteInputText] = useState('');
  const [isProgress, setIsProgress] = useState(false);
  const [shouldScrollDown, setShouldScrollDown] = useState<boolean>(false);
  // REFS
  const routeInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  // Context Hooks
  const authContext = useContext(AuthenticationContext);
  // Other Hooks
  const navigate = useNavigate();

  const handleSendMessage = async (query?: string) => {
    const textQuery = query ? query : routeInputText;

    if (isProgress) return;
    if (textQuery === '') return;
    if (routeInputRef.current !== null) routeInputRef.current!.value = '';

    // Redirect to auth if not found
    const tokens = authContext?.tokens;
    if (tokens === null) {
      console.log('token:', tokens);
      return navigate('/auth');
    }

    addRouteMessage(setRouteMessages, { isUser: true, title: textQuery });

    setIsProgress(true);
    setRouteInputText('');
    setShouldScrollDown(true);

    // ** Here is where the main api call happens ** //
    const response = await sendRouteMessage(textQuery);
    console.log('response--->', response);
    //* extra error handling *//
    if (
      !response ||
      response.status !== 200 ||
      response.status === 400 ||
      response.status === 404 ||
      response.status === 500
    ) {
      handleNoRouteResponse(setRouteMessages, response);
      setIsProgress(false);
      setShouldScrollDown(true);
      return;
    }

    const typedResponse = response.data as IRoutePlannerResponse;

    if (response.status === 200 || response.status === 201) {
      const mainRoutes = typedResponse?.data?.results || [];
      addRouteMessage(setRouteMessages, {
        isUser: false,
        title: typedResponse.message,
        routeList: mainRoutes,
      });
    }
    setIsProgress(false);
    setShouldScrollDown(true);
  };

  /* Cycle through chat history or submit query */
  const handleKeyDown = async (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      await handleSendMessage();
    }
  };

  return (
    <Container>
      <RoutePlannerTitle>Route Planner</RoutePlannerTitle>
      <RoutePlannerContent
        routeMessages={routeMessages}
        shouldScrollDown={shouldScrollDown}
        setShouldScrollDown={setShouldScrollDown}
        containerRef={containerRef}
      />
      <RoutePlannerInput
        routeInputRef={routeInputRef}
        handleKeyDown={handleKeyDown}
        routeInputText={routeInputText}
        setRouteInputText={setRouteInputText}
        isProgress={isProgress}
        onSendMessageClickListener={handleSendMessage}
      />
    </Container>
  );
};

export default RoutePlannerPage;

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 87vh;
  display: grid;
  grid-template-rows: min-content 1fr auto;
  // border: 2px solid red;
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    padding-left: 32px;
  }
`;

const RoutePlannerTitle = styled(H1Title)`
  margin-top: 1rem;
  font-size: 80px;
  @media only screen and (max-width: ${sizes.desktopS}) {
    /* Styles for screens less than 1920px */
    font-size: 50px;
  }
  @media only screen and (max-width: ${sizes.laptopMid}) {
    /* Styles for screens less than 1250px */
    font-size: 40px;
  }
  @media only screen and (max-width: ${sizes.tablet}) {
    /* Styles for screens less than 768px */
    font-size: 35px;
  }
  @media only screen and (max-width: ${sizes.mobileL}) {
    /* Styles for screens less than 425px */
    font-size: 30px;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardEvent, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import {
  Input,
  InputContainer,
  SendButton,
  VoiceButton,
} from './ChatPage.styled';
import { ChatResetTooltip } from '../../../Components/Tooltips/ChatResetTooltip';
import { Image } from '../../../App/Styles/Image.styled';

interface ChatPageInputProps {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => Promise<void>;
  inputText: string;
  setInputText: (value: React.SetStateAction<string>) => void;
  isProgress: boolean;
  onSendMessageClickListener: (query?: string) => Promise<void>;
  isMessages: boolean;
}

export const ChatPageInput: React.FC<ChatPageInputProps> = ({
  inputRef,
  handleKeyDown,
  inputText,
  setInputText,
  isProgress,
  onSendMessageClickListener,
  isMessages,
}) => {
  const matches = useMediaQuery('(max-width:1320px)');
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  const toggleVoice = async () => {
    if (!browserSupportsSpeechRecognition) {
      alert(
        'Your browser does not support voice recognition! Please switch to a browser that supports voice recognition by default.'
      );
    }

    if (!isMicrophoneAvailable) {
      alert(
        'Please allow to the microphone access in order to use this feature.'
      );
    }

    if (listening) {
      await SpeechRecognition.stopListening();
    } else {
      resetTranscript();
      await SpeechRecognition.startListening({ continuous: true });
    }
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = transcript;
    setInputText(transcript);
  }, [transcript]);

  const wrapMessageSendListener = async () => {
    if (listening) {
      await SpeechRecognition.stopListening();
    }

    onSendMessageClickListener();
  };

  return (
    <InputContainer>
      <Input
        type="text"
        spellCheck={false}
        ref={inputRef}
        placeholder="Type your query here..."
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => setInputText(() => event.target.value)}
      />
      {isProgress ? (
        <CircularProgress
          size={matches ? 40 : 50}
          color="warning"
          sx={{
            marginLeft: '10px',
          }}
        />
      ) : (
        <>
          <SendButton onClick={wrapMessageSendListener} disabled={!inputText}>
            <Image
              src="https://www.svgrepo.com/show/520931/send-2.svg"
              width="3em"
              height="3em"
            />
          </SendButton>
          <VoiceButton recording={listening} onClick={toggleVoice}>
            <MicNoneOutlinedIcon />
          </VoiceButton>
          {isMessages && <ChatResetTooltip />}
        </>
      )}
    </InputContainer>
  );
};

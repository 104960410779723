import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse } from '@mui/material';
import styled from 'styled-components';
import { IMainRoute, IStop } from '../../../Models/RoutePlannerModel'; // Adjust the import path as needed
import RouteDetails from './RouteDetails';

interface MainRouteCardProps {
  route: IMainRoute;
}
const MainRouteCard: React.FC<MainRouteCardProps> = ({ route }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Helper function to get stops from subRoutes
  const getStops = (stops: IStop[]) => {
    if (!stops || stops.length === 0) return [];
    // We only take the destination for each subroute to avoid redundancy
    return stops.map((stop) => stop.destination);
  };

  // If there are only 2 cards
  const stopsLengthIs2 = route?.stops?.length === 2;

  // Extract stops from subRoutes
  const stops = getStops(route.stops || []);
  const overallCost =
    route?.rpm && route?.mileage
      ? Math.round(route.rpm * route.mileage * 100) / 100
      : 'n/a';
  return (
    <>
      <CardSummary>
        <ExpandToggle>
          <IconContainer>
            <IconContainer onClick={toggleExpand}>
              {isExpanded ? (
                <ExpandLessIcon style={{ color: '#AD8449' }} />
              ) : (
                <ExpandMoreIcon style={{ color: '#AD8449' }} />
              )}
            </IconContainer>
          </IconContainer>
        </ExpandToggle>
        <RouteInfo>
          <RouteTableHeader>
            <HeaderItem>Origin</HeaderItem>
            {stopsLengthIs2 ? (
              <HeaderItem>1st stop</HeaderItem>
            ) : (
              <>
                <HeaderItem>1st stop</HeaderItem>
                <HeaderItem>2nd stop</HeaderItem>
              </>
            )}
            <HeaderItem>Final stop</HeaderItem>
            <HeaderItem>Average RPM</HeaderItem>
            <HeaderItem>Distance</HeaderItem>
            <HeaderItem>Total Income</HeaderItem>
          </RouteTableHeader>
          <Divider />
          <RouteTableData>
            <DataItem>
              <LocationContainer>
                <CityText>{route.origin.city}</CityText>
                <StateText>{route.origin.state}</StateText>
              </LocationContainer>
            </DataItem>
            {stopsLengthIs2 ? (
              <DataItem>
                <LocationContainer>
                  <CityText>{stops[0].city}</CityText>
                  <StateText>{stops[0].state}</StateText>
                </LocationContainer>
              </DataItem>
            ) : (
              <>
                <DataItem>
                  <LocationContainer>
                    <CityText>{stops[0]?.city}</CityText>
                    <StateText>{stops[0]?.state}</StateText>
                  </LocationContainer>
                </DataItem>
                <DataItem>
                  <LocationContainer>
                    <CityText>{stops[1]?.city}</CityText>
                    <StateText>{stops[1]?.state}</StateText>
                  </LocationContainer>
                </DataItem>
              </>
            )}
            <DataItem>
              <LocationContainer>
                <CityText>{route.destination.city}</CityText>
                <StateText>{route.destination.state}</StateText>
              </LocationContainer>
            </DataItem>
            <DataItem>{route.rpm}</DataItem>
            <DataItem>{route.mileage} miles</DataItem>
            <DataItem>
              <IncomeText>${overallCost}</IncomeText>
            </DataItem>
          </RouteTableData>
        </RouteInfo>
      </CardSummary>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <RouteDetails route={route} />
      </Collapse>
    </>
  );
};

export default MainRouteCard;

// Styled Components for MainRouteCard (Unchanged)

const CardSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-right: 8px;
`;

const ExpandToggle = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 10px;
  transform: translateY(+5px);
`;

const RouteInfo = styled.div`
  flex: 1;
`;

const RouteTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: ${({ theme }) => theme.alternativeWhite};
  margin-top: 8px;
`;

const RouteTableData = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #ccc;
  margin-top: 10px;
`;

const HeaderItem = styled.div`
  flex: 1;
  text-align: center;
  padding: 8px 0; // Added padding on top and bottom
`;

const DataItem = styled.div`
  flex: 1;
  display: flex; // Use flexbox for alignment
  align-items: center; // Center content vertically
  justify-content: center; // Center content horizontally
  padding: 2px 0; // Keep the vertical padding
`;

const IconContainer = styled.div`
  transform: translate(4px, -1px);
  :hover {
    cursor: pointer;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%; // Match the height of the parent DataItem
  justify-content: center; // Center both city and state vertically
`;

const CityText = styled.span`
  color: #8c8c8c;
  font-size: 1em;
  margin-bottom: 2px;
`;

const StateText = styled.span`
  color: white;
  font-size: 0.9em;
`;
const IncomeText = styled.span`
  color: ${({ theme }) => theme.priceText};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #111111, #8c8c8c 45%, #111111);
  margin: 5px 0;
`;

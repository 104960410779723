/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import useChatApi from '../../Hooks/useApi/useChatApi';
import { ChildrenProps } from '../index';
import { AuthenticationContext } from '../Auth/AuthContext'; // Import the AuthenticationContext

interface IPluginContext {
  LB123Plugin: boolean;
  setLB123Plugin: (value: boolean) => void;
  truckstopPlugin: boolean;
  setTruckstopPlugin: (value: boolean) => void;
  manifoldPlugin: boolean;
  setManifoldPlugin: (value: boolean) => void;
  checkTruckStop: () => void;
  truckstopRateInsightsPlugin: boolean; // Added this line
  setTruckstopRateInsightsPlugin: (value: boolean) => void; // Added this line
}

const PluginContext = createContext<IPluginContext | null>(null);

const PluginContextProvider = ({ children }: ChildrenProps) => {
  const authContext = useContext(AuthenticationContext);
  const {
    checkTruckstopIntegration,
    check123LBIntegration,
    checkManifoldIntegration,
  } = useChatApi();
  const [, setCheckTruckStop] = useState<boolean>(true);
  const [LB123Plugin, setLB123Plugin] = useState<boolean>(false);
  const [truckstopPlugin, setTruckstopPlugin] = useState<boolean>(false);
  const [manifoldPlugin, setManifoldPlugin] = useState<boolean>(false);
  const POLLING_INTERVAL = 1 * 60 * 1000; // Poll every 1 minute (test)
  // const [truckstopRateInsightsPlugin, setTruckstopRateInsightsPlugin] =
  //   useState<boolean>(false);
  const isLoggedIn = authContext?.tokens;

  const checkTSIntegration = async () => {
    const isTSEnabled = await checkTruckstopIntegration();
    setTruckstopPlugin(isTSEnabled);
    // console.log('TS Integration', isTSEnabled);
  };
  const check123Integration = async () => {
    const is123Enabled = await check123LBIntegration();
    setLB123Plugin(is123Enabled);
    // console.log('is123Enabled', is123Enabled);
  };
  const checkManifoldBrokerIntegration = async () => {
    const isManifoldEnabled = await checkManifoldIntegration();
    setManifoldPlugin(isManifoldEnabled);
    // console.log('isManifoldEnabled', isManifoldEnabled);
  };

  useEffect(() => {
    // Only run the checks after the user logs in
    if (isLoggedIn) {
      const runChecks = async () => {
        await checkTSIntegration();
        await check123Integration();
        await checkManifoldBrokerIntegration();
      };

      runChecks();

      const interval = setInterval(() => {
        const now = new Date();
        const timeString = now.toLocaleTimeString();
        console.log(`${timeString} - 123CHECK!`);
        check123Integration();
      }, POLLING_INTERVAL);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isLoggedIn]);

  const contextData = {
    LB123Plugin: LB123Plugin,
    setLB123Plugin: (value: boolean) => setLB123Plugin(value),
    truckstopPlugin: truckstopPlugin,
    setTruckstopPlugin: (value: boolean) => setTruckstopPlugin(value),
    manifoldPlugin: manifoldPlugin,
    setManifoldPlugin: (value: boolean) => setManifoldPlugin(value),
    // truckstopRateInsightsPlugin: truckstopRateInsightsPlugin, // Added this line
    // setTruckstopRateInsightsPlugin: (value: boolean) =>
    //   setTruckstopRateInsightsPlugin(value), // Added this line
    checkTruckStop: () => setCheckTruckStop(true),
  } as IPluginContext;

  return (
    <PluginContext.Provider value={contextData}>
      {children}
    </PluginContext.Provider>
  );
};

export { PluginContext, PluginContextProvider };

import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import { useTypewriter } from 'react-simple-typewriter';
import { Title } from '../../App/Styles/Typography.styled';
import { IUser } from '../../Models/UserModel';
import {
  IMainRoute,
  IStop,
  IStopDetails,
} from '../../Models/RoutePlannerModel';
import {
  generateRouteEmailBody,
  generateRouteEmailSubject,
} from '../../Utils/generateRouteEmailTemplate';

interface RouteEmailDraftModalProps {
  isOpen: boolean;
  onClose: () => void;
  mainRouteData: IMainRoute;
  stopDetailData: IStopDetails | undefined;
  stopData: IStop;
  userProfileData: IUser;
}

const RouteEmailDraftModal: React.FC<RouteEmailDraftModalProps> = ({
  isOpen,
  onClose,
  mainRouteData,
  stopDetailData,
  userProfileData,
  stopData,
}) => {
  const [subject, setSubject] = useState<string>('');
  const [textBody, setTextBody] = useState<string>('');
  const [typingComplete, setTypingComplete] = useState<boolean>(false);

  const [text] = useTypewriter({
    // Since we don't have the load data, you might want to handle this differently
    words: [
      generateRouteEmailBody(
        mainRouteData,
        stopDetailData,
        userProfileData,
        stopData
      ),
    ],
    typeSpeed: 9,
    onLoopDone: () => setTypingComplete(true),
  });

  useEffect(() => {
    const defaultSubject = generateRouteEmailSubject(
      mainRouteData,
      stopDetailData,
      userProfileData,
      stopData
    );

    setSubject(defaultSubject);

    if (typingComplete) {
      setTextBody(
        generateRouteEmailBody(
          mainRouteData,
          stopDetailData,
          userProfileData,
          stopData
        )
      );
    }
  }, [
    mainRouteData,
    stopData,
    stopDetailData,
    typingComplete,
    userProfileData,
  ]);
  // debugger;
  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleTextBodyChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextBody(event.target.value);
  };

  const handleClose = () => {
    onClose();
    setTypingComplete(false);
    setTextBody('');
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <TransparentModal onClick={handleClose}>
        <Container onClick={(e) => e.stopPropagation()}>
          <Title bold={true}>Subject</Title>
          <Input type={'text'} value={subject} onChange={handleSubjectChange} />
          <Title bold={true}>Message</Title>
          <TextAreaContainer>
            <TextArea
              spellCheck={false}
              value={typingComplete ? textBody : text}
              onChange={handleTextBodyChange}
              readOnly={!typingComplete}
            />
          </TextAreaContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleClose}
              href={`mailto:${
                stopDetailData?.email
                  ? stopDetailData?.email
                  : 'example@example.com'
              }?subject=${encodeURIComponent(
                subject
              )}&body=${encodeURIComponent(textBody)}`}
            >
              <Title bold={true}>Send Email</Title>
            </Button>
            <Button onClick={handleClose}>
              <Title bold={true}>Cancel</Title>
            </Button>
          </div>
        </Container>
      </TransparentModal>
    </Modal>
  );
};

export default RouteEmailDraftModal;

const TransparentModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  padding: 20px;
  max-height: 85%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.secondary};
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;

  ${Title} {
    word-wrap: break-word;
    overflow: hidden;
  }
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.primary};
  border: none;
  height: 2.5rem;
  border-radius: 10px;
  color: ${({ theme }) => theme.text};
  padding: 0.5rem;

  &:focus {
    outline: 0;
  }
`;

const Button = styled.a`
  width: 48%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.title};
  transition: ${({ theme }) => theme.transition};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  opacity: 0.8;

  ${Title} {
    color: ${({ theme }) => theme.primary};
  }

  &:last-of-type {
    background-color: ${({ theme }) => theme.redBackground};
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    opacity: 1;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const TextArea = styled.textarea`
  overflow: auto;
  resize: none;
  min-width: 500px;
  min-height: 350px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  border: none;
  padding: 0.5rem;
  border-radius: 10px;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: 600px) {
    min-width: 300px;
    max-width: 300px;
  }
`;

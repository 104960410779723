import styled from 'styled-components';
import { IRouteMessageItem } from '../../Models/RoutePlannerModel';
import {
  MessageRow,
  QueryContainer,
  StyledTitleLine,
} from '../../Components/Recycler/MessageRecycler';
import { Paragraph, StyledDiv } from '../../App/Styles/Typography.styled';
import MainRouteCard from './RouteCard/MainRouteCard';
import { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';

interface RouteMessageRecyclerProps {
  routeMessages: IRouteMessageItem[];
  shouldScrollDown: boolean;
  setShouldScrollDown: React.Dispatch<React.SetStateAction<boolean>>;
  containerRef: React.RefObject<HTMLDivElement>;
}

const RouteMessageRecycler: React.FC<RouteMessageRecyclerProps> = ({
  routeMessages,
  shouldScrollDown,
  setShouldScrollDown,
  containerRef,
}) => {
  useEffect(() => {
    if (shouldScrollDown && containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
      setShouldScrollDown(false);
    }
  }, [containerRef, shouldScrollDown, setShouldScrollDown]);

  return (
    <Container ref={containerRef}>
      {routeMessages.map((item, index) => {
        const isUser = item.isUser;
        const cardShouldAppear = item.routeList && item.routeList.length > 0;
        return (
          <MessageRow isUser={isUser} shouldOverflow={false}>
            {item.title && (
              <RouteQueryContainer isUser={isUser}>
                {isUser ? (
                  <Paragraph>{item.title}</Paragraph>
                ) : (
                  <Paragraph as="div" className="fade-container">
                    <StyledDiv>
                      <StyledTitleLine>{item.title}</StyledTitleLine>
                    </StyledDiv>
                  </Paragraph>
                )}
              </RouteQueryContainer>
            )}
            {cardShouldAppear &&
              item.routeList?.map((route, index) => (
                <FadeCard
                  duration={800}
                  delay={200}
                  triggerOnce
                  style={{ minWidth: '100%' }}
                  cascade
                >
                  <MainRouteCard route={route} key={index}></MainRouteCard>
                </FadeCard>
              ))}
          </MessageRow>
        );
      })}
    </Container>
  );
};
export default RouteMessageRecycler;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  grid-row: 2;
  padding-top: 5px;
`;

const RouteQueryContainer = styled(QueryContainer)`
  margin: 10px 0;
`;

const FadeCard = styled(Fade)`
  width: 100%;
  background-image: ${(props) =>
    `linear-gradient(to bottom right, ${props.theme.secondary}, ${props.theme.primary})`};

  margin-bottom: 2px;
  padding: 2px;
  border-radius: 8px;
  border-top: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-left: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-right: 1px solid ${(props) => `${props.theme.detailsText}30`};
  border-bottom: 0.5px solid ${(props) => `${props.theme.detailsText}30`};
`;

import { Tooltip } from '@mui/material';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';

import { clearCachedMessages } from '../../Utils/messagesSessionStorage';

export const ChatResetTooltip = () => {
  return (
    <Tooltip title="Reset Chat" placement="top">
      <MuiTrashIcon
        onClick={() => {
          clearCachedMessages();
          window.location.reload();
        }}
      />
    </Tooltip>
  );
};

export const MuiTrashIcon = styled(DeleteIcon)`
  font-size: 35px !important;
  color: ${({ theme }) => theme.invertedBWColor};
  transform: translateY(-6px);
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.hoverAlternativeColor};
  }
  @media only screen and (max-width: 1320px) {
    /* Styles for screens less than 1320px */
    transform: translateY(-2px);
  }
`;

import { FC, Children } from 'react';
import styled from 'styled-components';
import NavigationLoginLayout from '../../Layouts/Navigation/NavigationLogInLayout';
import BottombarLayout from '../../Layouts/Bottombar/BottombarLayout';
import {
  HeadTitle,
  Title,
  Paragraph,
  Text,
} from '../../App/Styles/Typography.styled';
import StyledIFrame from '../../Components/StyledIFrame/StyledIFrame';
import { nameToRgba } from '../../Utils/colorUtils';
import { devices } from '../../Utils/screenUtil';
import { ChildrenProps } from '../../Context';

interface GridItemProps {
  color?: string | undefined;
  full?: boolean | undefined;
  top?: boolean | undefined;
}

interface ListProps extends ChildrenProps {
  nocol?: boolean | undefined;
}

const COLORS = {
  red: '#ff595e',
  orange: '#fe7f2d',
  yellow: '#ffca3a',
  green: '#8ac926',
  pink: '#ff006e',
  blue: '#1982c4',
  purple: '#6a4c93',
};

const loomEmbedParams = {
  muted: 'false',
  autoplay: 'false',
  hide_share: 'false',
  hideEmbedTopBar: 'true',
  hide_title: 'true',
  hide_owner: 'true',
};

const youtubeURL = 'https://www.youtube.com/embed/iMxkpSPCDlE';

// const createLoomUrl = () => {
//   const params = new URLSearchParams(loomEmbedParams).toString();
//   return `https://www.loom.com/embed/caf0babb78db47aca0ab2e71e8701f73?${params}`;
// };

const List: FC<ListProps> = ({ nocol, children }) => {
  return (
    <UnorderedList nocol={nocol}>
      {Children.map(children, (child) => (
        <ListItem>{child}</ListItem>
      ))}
    </UnorderedList>
  );
};

const HelpPage: FC = () => {
  return (
    <Container>
      <NavigationLoginLayout />
      <ContentContainer>
        <StyledIFrame src={youtubeURL} title="Tarmac-AI Demo" width="80%" />

        <GuideContainer>
          <PageTitle bold>Guide For First Time Users</PageTitle>
          <StyledTitle bold>Useful Tips</StyledTitle>
          <List>
            <StyledParagraph>
              Start with asking loads from any{' '}
              <Highlight color={COLORS.green}>state or city</Highlight> by
              mentioning a <Highlight color={COLORS.blue}>truck type</Highlight>{' '}
              such as “loads from Austin for van trucks” or from “loads from
              Texas for flatbed”.
            </StyledParagraph>
            <StyledParagraph>
              If you don’t mention the{' '}
              <Highlight color={COLORS.blue}>truck type</Highlight>, the system
              will ask you to choose the truck type by providing the options.
            </StyledParagraph>
            <StyledParagraph>
              You can ask follow up questions by not mentioning the{' '}
              <Highlight color={COLORS.green}>state or city</Highlight> and the
              system will provide the results from the same state or city.
            </StyledParagraph>
            <StyledParagraph>
              But when you want to find loads from different states or city,
              just ask loads from a different state or city.
            </StyledParagraph>
            <StyledParagraph>
              The system will only provide answers to{' '}
              <Highlight color={COLORS.red}>price</Highlight>,{' '}
              <Highlight color={COLORS.pink}>rate per mile</Highlight>,{' '}
              <Highlight color={COLORS.purple}>load</Highlight>,{' '}
              <Highlight color={COLORS.blue}>pick up</Highlight>,{' '}
              <Highlight color={COLORS.orange}>origin</Highlight>,{' '}
              <Highlight color={COLORS.yellow}>post age</Highlight>,{' '}
              <Highlight color={COLORS.red}>company</Highlight>,{' '}
              <Highlight color={COLORS.green}>equipment</Highlight>,{' '}
              <Highlight color={COLORS.blue}>mileage</Highlight> related
              questions or double, triple or more combinations of the factors.
              See the below playbook for some sample prompts.
            </StyledParagraph>
            <StyledParagraph>
              If you don't specify the number of loads, the system will give 5
              loads by default.
            </StyledParagraph>
          </List>
        </GuideContainer>

        <SamplePromptsContainer>
          <PageTitle bold>Sample Prompts: </PageTitle>
          <SubTitle centered>
            <StyledParagraph fontSize="0.9em">
              (Subject to Availability of Data)
            </StyledParagraph>
            <Italic>
              <StyledParagraph>
                Mention the{' '}
                <Highlight color={COLORS.blue}>truck type</Highlight> when you
                initiate a search from any city or state. Otherwise the system
                will ask you to choose the truck type.
              </StyledParagraph>
            </Italic>
          </SubTitle>

          <StyledTitle bold>Basic Prompts:</StyledTitle>
          <GridContainer>
            <GridItem color={COLORS.green}>
              <SubTitle bold>City/Location Origin</SubTitle>
              <List nocol>
                <StyledParagraph>
                  Give me loads from Austin for Van trucks.
                </StyledParagraph>
              </List>
            </GridItem>
            <GridItem color={COLORS.yellow}>
              <SubTitle bold>Load Amount</SubTitle>
              <List nocol>
                <StyledParagraph>
                  Tell me 23 loads from Atlanta for Step Deck trucks.
                </StyledParagraph>
                <StyledParagraph>
                  Give me 15 loads from Charlotte for flatbed trucks.
                </StyledParagraph>
              </List>
            </GridItem>
            <GridItem color={COLORS.orange}>
              <SubTitle bold>Mileage</SubTitle>
              <List nocol>
                <StyledParagraph>
                  List of loads from Fort Worth with less than 150 miles.
                </StyledParagraph>
                <StyledParagraph>
                  Loads from Jacksonville with transportation distance higher
                  than 250 miles.
                </StyledParagraph>
              </List>
            </GridItem>
            <GridItem color={COLORS.red}>
              <SubTitle bold>Price</SubTitle>
              <List nocol>
                <StyledParagraph>
                  Loads from Kansas City with higher than $1300 price.
                </StyledParagraph>
                <StyledParagraph>
                  Give 7 Loads from Houston with RPM (or rate per mile) higher
                  than 1.7.
                </StyledParagraph>
                <StyledParagraph>
                  Loads from Chicago lower than $1300 price.
                </StyledParagraph>
                <StyledParagraph>
                  Loads from Dallas  with RPM (or rate per mile) less than 1.7.
                </StyledParagraph>
              </List>
            </GridItem>
            <GridItem color={COLORS.blue}>
              <SubTitle bold>Truck Type</SubTitle>
              <List nocol>
                <StyledParagraph>
                  {' '}
                  Loads with flatbed trucks from Austin.
                </StyledParagraph>
                <StyledParagraph>
                  {' '}
                  Loads from Phoenix for Van trucks.
                </StyledParagraph>
              </List>
            </GridItem>
            <GridItem color={COLORS.purple}>
              <SubTitle bold>Pick Up Dates</SubTitle>
              <List nocol>
                <StyledParagraph>
                  Loads from San Antonio with pick up date after 15th of May.
                </StyledParagraph>
                <StyledParagraph>
                  Loads from Albany with pick up date before 12th of June.
                </StyledParagraph>
              </List>
            </GridItem>
          </GridContainer>

          <StyledTitle bold>Combine Two Factors:</StyledTitle>
          <GridContainer>
            <GridItem full>
              <SubTitle bold>Age + Origin</SubTitle>
              <StyledParagraph>
                Loads from Baltimore posted in last 10 hours.
              </StyledParagraph>
              <StyledParagraph>
                Loads from Buffalo posted in last 12 hours minutes.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Age + Destination</SubTitle>
              <StyledParagraph>
                Loads to Dewitt city from Buffalo posted in last 24 hour.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Equipment type</SubTitle>
              <StyledParagraph>
                List of loads from Houston posted in last 12 hours for Flatbed.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Age + Price</SubTitle>
              <StyledParagraph>
                List of loads from Charlotte posted in last 10 hours higher than
                $1000.
              </StyledParagraph>
              <StyledParagraph>
                List of loads from Baltimore posted in last 22 hours lower than
                $1.5 rate per mile.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Age + Mileage</SubTitle>
              <StyledParagraph>
                List of loads from Seattle posted in last 21 hours with lower
                than 1100 miles.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Age + Pickup</SubTitle>
              <StyledParagraph>
                Get me loads from Memphis posted in last 30 minutes with a
                pickup date after 3rd of June.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Origin + Equipment type</SubTitle>
              <StyledParagraph>
                Loads from Brooklyn for flatbed trucks.
              </StyledParagraph>
              <StyledParagraph>
                Give me Loads from Austin for Van trucks.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Origin + Price</SubTitle>
              <StyledParagraph>
                Give me loads priced higher than $350 from Albany, NY.
              </StyledParagraph>
              <StyledParagraph>
                Loads priced lower than $12.5 rate per mile from Amarillo.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Origin + Mileage</SubTitle>
              <StyledParagraph>
                Loads from Kansas city with less than 750 miles.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Origin + Pickup date</SubTitle>
              <StyledParagraph>
                Loads from Kansas City, MO with pickup date after June3rd.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Equipment + Price:</SubTitle>
              <StyledParagraph>
                Flatbed truck loads from Austin higher than $1200.
              </StyledParagraph>
              <StyledParagraph>
                Reefer truck loads from Nashville higher than $1.2 rate per
                mile.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Equipment + Pickup:</SubTitle>
              <StyledParagraph>
                Van truck loads from Atlanta with pickup date after 3rd of June.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Price + Mileage:</SubTitle>
              <StyledParagraph>
                Loads from Memphis  higher than $500 but less than 750 miles.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Price + Pickup:</SubTitle>
              <StyledParagraph>
                Loads from Boston higher than $500 in price with pickup date
                after June 5th.
              </StyledParagraph>
              <StyledParagraph>
                Give 12 loads from Phoenix higher than $350 with pickup date
                after June 5th.
              </StyledParagraph>
            </GridItem>

            <GridItem full>
              <SubTitle bold>Mileage + Pickup:</SubTitle>
              <StyledParagraph>
                List of loads from Indianapolis less than a transportation
                distance of 1800 miles with pickup date after 20th of June.
              </StyledParagraph>
            </GridItem>
          </GridContainer>

          <StyledTitle bold>More Complicated Combinations:</StyledTitle>

          <GridContainer>
            <GridItem top>
              <StyledParagraph>
                Show me 9 loads from California posted in last 12 hours, higher
                than 500$, with pick up dates after June 30, rank them with
                highest rate per mile.
              </StyledParagraph>
            </GridItem>
            <GridItem top>
              <StyledParagraph>
                Show me 12 loads from Atlanta posted in last 23 hours with
                transportation distance higher than 300 miles but lower than
                $1500 in price.
              </StyledParagraph>
            </GridItem>
            <GridItem top>
              <StyledParagraph>
                Loads from Houston posted in the last 21 hours with cargo weight
                higher than 15000 lbs, pickup date after 25th June, rank them
                with highest rate per mile.
              </StyledParagraph>
            </GridItem>
          </GridContainer>
        </SamplePromptsContainer>
      </ContentContainer>
      <BottombarLayout />
    </Container>
  );
};

export default HelpPage;

const Container = styled.div`
  height: 100vh;
`;

const UnorderedList = styled.ul<{ nocol?: boolean | undefined }>`
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 2rem;
  color: ${({ theme }) => theme.detailsText};

  ${(props) =>
    !props.nocol &&
    `
    @media ${devices.tablet} {
      // Does not work for IE < 10
      -moz-column-count: 2;
      -moz-column-gap: 2rem;
      -webkit-column-count: 2;
      -webkit-column-gap: 2rem;
      column-count: 2;
      column-gap: 2rem;

      <!--[if lt IE 10]>
        float: left;
      <!endif-->
    }
  `}
`;
const ListItem = styled.li`
  padding: 0.5em;
  line-height: 1.5;
  color: ${({ theme }) => theme.accent};
`;
const Italic = styled(Text)`
  font-style: italic;
  color: inherit;
`;
const PageTitle = styled(HeadTitle)`
  color: ${({ theme }) => theme.text};
  font-size: 2em;
  text-align: center;
`;

const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.text};
  font-size: 1.5em;
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
`;

const SubTitle = styled(Title)<{ centered?: boolean | undefined }>`
  margin-top: 0.5em;
  padding: 1rem 0;
  color: ${({ theme }) => theme.text};
  ${({ centered }) => centered && 'text-align: center;'}
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 1.1em;
`;

const Highlight = styled.span<{ color: string }>`
  background-color: ${({ color }) => nameToRgba(color, 0.6)};
  padding: 0 0.2em;
  border-radius: 0.1em;
  color: inherit;
`;

const ContentContainer = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const GuideContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.sideMenu};
  border-color: ${({ theme }) => theme.settingsBorderColor};
  padding: 2rem;
  gap: 1rem;
  border-radius: 20px;
  width: 90%;
  margin: 3rem auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    border-top: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-left: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-right: 1px solid ${({ theme }) => `${theme.detailsText}30`};
    border-bottom: none;
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    pointer-events: none;
  }
`;

const SamplePromptsContainer = styled(GuideContainer)`
  width: 100%;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media ${devices.tablet} {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`;

const GridItem = styled.div<GridItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  ${({ full }) => full && 'width: 100%;'}
  background-color: ${(props) =>
    props.color ? nameToRgba(props.color, 0.75) : props.theme.secondary};
  padding: ${({ top }) => (top ? '2rem' : 0)} 2rem 2rem 2rem;
  border-radius: 10px;

  @media ${devices.tablet} {
    &:nth-child(odd) {
      grid-column: 1;
      justify-self: end;
      align-items: end;
      margin-right: 1rem;
      text-align: right;
    }

    &:nth-child(even) {
      grid-column: 2;
      justify-self: start;
      align-items: start;
      margin-left: 1rem;
    }
  }
`;

import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { setCookie } from 'react-use-cookie';

import { IAccessToken, ITokens } from '../Models/UserModel';
import { getParsedCookie } from './cookieUtil';
import { REFRESH_ENDPOINT } from '../App/Axios/endpoints';
import { IResponse } from '../Models/BaseModel';

// Helper function to add token interceptor to an axios instance
export const addAuthInterceptor = (axiosInstance: any) => {
  axiosInstance.interceptors.request.use(
    async (request: AxiosRequestConfig) => {
      const jwtTokens = getParsedCookie('tokens');
      if (!request.headers) {
        throw new Error(`Expected request and request.headers not exists`);
      }
      if (!jwtTokens?.access) {
        throw new Error('No access token available');
      }

      const user = jwt_decode(jwtTokens.access) as IAccessToken;
      const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;

      if (!isExpired) return request;

      // If expired, refresh token
      const response = await axios.post(REFRESH_ENDPOINT, {
        refresh: jwtTokens?.refresh,
      });
      const new_response = response.data as IResponse<ITokens>;
      const data = new_response.data;
      setCookie('tokens', JSON.stringify(data));
      request.headers.Authorization = `Bearer ${data.access}`;
      return request;
    },
    (error: AxiosError) => {
      console.error('Interceptor error:', error);
      return Promise.reject(error);
    }
  );
};

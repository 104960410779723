import { CircularProgress } from '@mui/material';

interface CustomCircularLoadingProps {
  size?: number;
  marginTop?: number;
}

export const CustomCircularLoading: React.FC<CustomCircularLoadingProps> = ({
  size = 35,
  marginTop = 24,
}) => {
  return (
    <CircularProgress
      size={size}
      color="warning"
      sx={{ marginTop: `${marginTop}px` }}
    />
  );
};

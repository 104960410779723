import React from 'react';
import styled from 'styled-components';
import { IMainRoute } from '../../../Models/RoutePlannerModel';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import StopCard from './StopCard';

// RouteDetails component for expanded view
const RouteDetails: React.FC<{ route: IMainRoute }> = ({ route }) => {
  return (
    <DetailsContainer>
      <StopsContainer>
        {route.stops &&
          route.stops.map((stop, index) => (
            <React.Fragment key={index}>
              <StopCard stop={stop} mainRouteData={route} />
              {index < route.stops.length - 1 && (
                <ArrowContainer>
                  <EastRoundedIcon
                    style={{ color: '#AD8449', fontSize: '2em' }}
                  />
                </ArrowContainer>
              )}
            </React.Fragment>
          ))}
      </StopsContainer>
    </DetailsContainer>
  );
};

export default RouteDetails;

// Updated Styled Components for RouteDetails
const DetailsContainer = styled.div`
  padding: 10px;
  margin-top: 10px;
  // Animation for slide in/out
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 1000px; // Set to a high value to ensure all content is visible when expanded
  overflow: hidden;
  opacity: 1;
`;

const StopsContainer = styled.div`
  display: flex;
  overflow-x: auto; // Allows horizontal scrolling if there are more stops
  align-items: center; // Ensures vertical centering of the arrow
  // scrollbar-color: rgba(255, 255, 255, 0.18) transparent;

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.18);
    border-radius: 6px; /* Rounded corners for the thumb */
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; // Adjust width to control spacing between MiddleRoute blocks
  flex-shrink: 0;
`;

export function formatPhoneNumber(number: any | string): string {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return number
}


export function numberWithCommas(propValue: any): string {
    if (propValue === null || propValue === undefined) {
        return '';
    }

    let num = Number(propValue); // Ensure x is treated as a number
    if (isNaN(num)) {
        return ''; // Return an empty string if x is not a valid number
    }

    // Round the number to two decimal places
    num = Math.round(num * 100) / 100;

    let parts = num.toString().split('.');
    let whole = parts[0];
    let decimal = parts[1] || ''; // Ensure we handle cases where there's no decimal part

    // Ensure the decimal part is two digits long
    if (decimal.length === 1) {
        decimal += '0';
    }

    // Format the whole part with commas
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimal ? `${whole}.${decimal}` : whole;
}

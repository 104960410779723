import { Toaster } from 'react-hot-toast';

const ToasterConfig = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={true}
      toastOptions={{
        success: {
          duration: 3000,
          style: {
            background: '#1e1e1e', // Dark background for success
            color: '#fff', // White text
            borderRadius: '6px', // Slightly rounded corners
            padding: '16px 24px',
            maxWidth: '500px',
            border: '1px solid rgb(59, 59, 59)',
          },
        },
        error: {
          duration: 3000,
          style: {
            background: '#8B0000', // Dark red background for errors
            color: '#fff', // White text
            borderRadius: '8px', // More rounded corners
            padding: '16px 24px',
            maxWidth: '500px',
            border: '1px solid darkgray',
          },
        },
      }}
    />
  );
};

export default ToasterConfig;

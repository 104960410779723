import { MessageItem } from '../Models/LoadModel';

export const setCachedSessionStorageMessages = (messages: MessageItem[]) => {
  const timestamp = Date.now();

  // Add `isCachedData` to each message item
  const processedMessages = messages.map((message) => ({
    ...message,
    isCachedData: true,
  }));

  const cachedData = { messages: processedMessages, timestamp };
  sessionStorage.setItem('chatMessages', JSON.stringify(cachedData));
};

export const getCachedMessages = () => {
  const storedData = sessionStorage.getItem('chatMessages');
  if (!storedData) return [];

  const data = JSON.parse(storedData);
  const expirationTime = 30 * 60 * 1000; // 30 minutes

  if (Date.now() - data.timestamp > expirationTime) {
    sessionStorage.removeItem('chatMessages');
    return [];
  }

  // Add `isCachedData` to each message item
  const cachedData = data.messages.map((message: MessageItem) => ({
    ...message,
    isCachedData: true,
  }));

  return cachedData as MessageItem[];
};

export const clearCachedMessages = () => {
  sessionStorage.removeItem('chatMessages');
};

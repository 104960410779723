import { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeContext } from '../Context/Theme/ThemeContext';
import Router from '../Routes/Router';
import GlobalStyle from './Styles/Global.styled';
import { setupThemeProvider } from './Themes/themes';
import { PortalContext } from '../Context/Portal/PortalContext';
import PortalLayout from '../Layouts/Portal/PortalLayout';
import ToasterConfig from '../Components/ToasterConfig/ToasterConfig';

const App = () => {
  const portalContext = useContext(PortalContext);
  const themeContext = useContext(ThemeContext);
  const theme = themeContext?.theme;

  return (
    <ThemeProvider theme={setupThemeProvider(theme!)}>
      <Container>
        <GlobalStyle />
        <Router />
      </Container>
      {portalContext?.isOpen && (
        <PortalLayout onClose={() => portalContext?.setIsOpen(false)}>
          {portalContext?.modal}
        </PortalLayout>
      )}
      <ToasterConfig />
    </ThemeProvider>
  );
};

export default App;

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary};
`;

import { useState } from 'react';
import styled from 'styled-components';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

import { useFeedbackMutation } from '../../Hooks/useApi/useMutations/useFeedbackMutation';

type ChatPageFeedbackIconsProps = {
  currentPromptText: string;
  openFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFeedbackGiven: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatPageFeedbackIcons: React.FC<ChatPageFeedbackIconsProps> = ({
  currentPromptText,
  openFeedbackModal,
  setFeedbackGiven,
}) => {
  const { sendFeedbackFn } = useFeedbackMutation();

  const [isThumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [isThumbsDownClicked, setThumbsDownClicked] = useState(false);

  const handleThumbsUpClick = async () => {
    if (currentPromptText) {
      setThumbsUpClicked(true);
      sendFeedbackFn({
        prompt: currentPromptText,
        isPositive: true,
        comment: '',
      });
      setThumbsUpClicked(false);
      setFeedbackGiven(true);
    }
  };

  const handleThumbsDownClick = () => {
    setThumbsDownClicked(true);
    openFeedbackModal(true); // Modal only opens for thumbs down
    setThumbsDownClicked(false);
  };

  return (
    <FeedBackContainer isUser={false}>
      <FeedbackIconUp
        isClicked={isThumbsUpClicked}
        onClick={handleThumbsUpClick}
        onMouseDown={() => setThumbsUpClicked(true)}
        onMouseUp={() => setThumbsUpClicked(false)}
      />
      <FeedbackIconDown
        isClicked={isThumbsDownClicked}
        onClick={handleThumbsDownClick}
        onMouseDown={() => setThumbsDownClicked(true)}
        onMouseUp={() => setThumbsDownClicked(false)}
      />
    </FeedBackContainer>
  );
};

export default ChatPageFeedbackIcons;

const FeedBackContainer = styled.div<{ isUser?: boolean | undefined }>`
  padding: 0.75rem;
  background-color: ${(props) => props.theme.secondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: ${(props) =>
    props.isUser ? '0.5rem 0px 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0.5rem'};
  margin: 0;
  gap: 1rem;

  .fade-container {
    color: ${(props) => props.theme.text};
  }
  flex-direction: row;
  position: relative;
  color: #e7e7e7;
`;

const FeedbackIconUp = styled(ThumbUpAltOutlinedIcon)<{ isClicked: boolean }>`
  opacity: ${(props) => (props.isClicked ? '0.5' : '1')};
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
`;

const FeedbackIconDown = styled(ThumbDownOffAltOutlinedIcon)<{
  isClicked: boolean;
}>`
  opacity: ${(props) => (props.isClicked ? '0.5' : '1')};
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
`;

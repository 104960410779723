import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { setCookie } from 'react-use-cookie';
import { getParsedCookie } from '../../Utils/cookieUtil';
import { IAccessToken, ITokens } from '../../Models/UserModel';
import { REFRESH_ENDPOINT } from '../../App/Axios/endpoints';
import { IResponse } from '../../Models/BaseModel';

const useAxios = (isAuth: boolean = false) => {
  if (!isAuth) {
    return axios.create({
      baseURL: process.env.REACT_APP_SERVER_API_URL, // Replace with your API base URL
      timeout: 5000, // Set a timeout for requests
    });
  }
  // getting user tokens
  const jwtTokens = getParsedCookie('tokens');
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_API_URL,
    headers: { Authorization: `Bearer ${jwtTokens?.access}` },
  });
  // Setup Interceptor Request
  axiosInstance.interceptors.request.use(async (request) => {
    // checks if we have any headers in request
    if (!request.headers) {
      throw new Error(`Expected request and request.headers not exists`);
    }
    const user = jwt_decode(jwtTokens?.access!) as IAccessToken;
    // Compare days and get isExpired value
    const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1;
    // console.log('isExpired: ', isExpired);
    // if not expired - good!
    if (!isExpired) return request;
    // if expired - refresh token
    const response = await axios.post(REFRESH_ENDPOINT, {
      refresh: jwtTokens?.refresh,
    });
    const new_response = response.data as IResponse<ITokens>;
    const data = new_response.data;
    // console.log(`new tokens ${data.access}`);
    // const tokenInfo = jwt_decode(data.access)
    setCookie('tokens', JSON.stringify(data));
    request.headers.Authorization = `Bearer ${data.access}`;
    return request;
  });
  return axiosInstance;
};

export default useAxios;

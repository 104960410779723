import React, {
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

import { Paragraph, StyledDiv } from '../../App/Styles/Typography.styled';
import TypePrompt from '../TypePrompt/TypePrompt';
import { PortalContext } from '../../Context/Portal/PortalContext';
import { AddPromptModal } from '../Modal/AddPromptModal';
import { LazyTable, TableMessageContainer } from './LazyTable';
import { CollapsibleTable } from '../Table/CollapsibleTable';
import { FeedBackModal } from '../Modal/FeedBackModal';
import ChatPageFeedbackIcons from './ChatPageFeedbackIcons';
import { MessageItem } from '../../Models/LoadModel';

type MessageRecyclerProps = {
  messages: MessageItem[];
  handleSelect: (value: string) => void;
  sendNewMessage: (query?: string) => Promise<void>;
  feedbackGiven: boolean;
  setFeedbackGiven: React.Dispatch<React.SetStateAction<boolean>>;
};

const MessageRecycler: React.FC<MessageRecyclerProps> = ({
  messages,
  handleSelect,
  feedbackGiven,
  setFeedbackGiven,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const [currentPromptText, setCurrentPromptText] = useState<string>('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const portalContext = useContext(PortalContext);

  const scrollToMessage = useCallback((index: number) => {
    const messageElement = messageRefs.current[index];
    if (messageElement && containerRef.current) {
      const offsetTop = messageElement.offsetTop;
      containerRef.current.scrollTo({
        top: offsetTop - 200,
        behavior: 'smooth',
      });
    }
  }, []);

  // Scroll to the last message when a new message is added
  useEffect(() => {
    if (containerRef.current && messages.length > 0) {
      scrollToMessage(messages.length - 1);
    }
  }, [messages.length, scrollToMessage]);

  // Handle global wheel scrolling
  useEffect(() => {
    const handleGlobalScroll = (event: WheelEvent) => {
      if (containerRef.current) {
        const delta = event.deltaY || event.detail;
        containerRef.current.scrollTop += delta;
        event.preventDefault();
      }
    };
    window.addEventListener('wheel', handleGlobalScroll, { passive: false });
    return () => window.removeEventListener('wheel', handleGlobalScroll);
  }, []);

  return (
    <Container ref={containerRef}>
      {messages.map((item, index) => {
        const isUser = item.isUser;
        const tableShouldAppear = item.loadList && item.loadList.length > 0;
        const typePromptShouldAppear =
          item.promptTruckType && messages.length - 1 === index;
        const lastMessages = index >= messages.length - 5;
        const lastMessage = index === messages.length - 1;

        console.log(currentPromptText, 'currentPromptText');

        return (
          <MessageRow
            shouldOverflow={portalContext?.isOpen}
            isUser={isUser}
            key={index}
            ref={(el) => (messageRefs.current[index] = el)}
          >
            {item.title && !item.promptTruckType && (
              <QueryContainer isUser={isUser}>
                {isUser ? (
                  <Paragraph>{item.title}</Paragraph>
                ) : (
                  <Paragraph as="div" className="fade-container">
                    <Fade duration={item.isCachedData ? 0 : 400} triggerOnce>
                      <StyledDiv>
                        {item.userPrompt && item.overallPromptNum !== 1 && (
                          <PromptDiv>
                            <StyledLine>Your prompt:</StyledLine>
                            <StyledPrompt>{item.userPrompt}</StyledPrompt>
                          </PromptDiv>
                        )}
                        <StyledTitleLine>{item.title}</StyledTitleLine>
                        {item.isRelaxed && (
                          <>
                            <RelaxedText>
                              If you need to schedule a load please click{' '}
                            </RelaxedText>
                            <RelaxedSpanButton
                              onClick={() => {
                                setCurrentPromptText(item.userPrompt || '');
                                setIsModalOpen(true);
                              }}
                            >
                              here
                            </RelaxedSpanButton>
                          </>
                        )}
                      </StyledDiv>
                    </Fade>
                  </Paragraph>
                )}
              </QueryContainer>
            )}

            {tableShouldAppear && (
              <>
                {lastMessages ? (
                  // For the last messages, render the table normally
                  <Fade
                    duration={item.isCachedData ? 0 : 800}
                    delay={item.isCachedData ? 0 : 400}
                    triggerOnce
                    style={{ minWidth: '100%' }}
                  >
                    <TableMessageContainer>
                      <CollapsibleTable loadList={item.loadList || []} />
                    </TableMessageContainer>
                  </Fade>
                ) : (
                  // For all previous, render the table lazily
                  <Fade
                    duration={item.isCachedData ? 0 : 800}
                    delay={item.isCachedData ? 0 : 400}
                    triggerOnce
                    style={{ minWidth: '100%' }}
                  >
                    <LazyTable loadList={item.loadList || []} />
                  </Fade>
                )}
              </>
            )}

            {typePromptShouldAppear && (
              <Fade
                triggerOnce
                duration={item.isCachedData ? 0 : 800}
                delay={item.isCachedData ? 0 : 400}
              >
                <TruckPromptContainer isUser={isUser}>
                  <TypePrompt
                    handleSelect={handleSelect}
                    handleToggle={() => scrollToMessage(messages.length - 1)}
                  />
                </TruckPromptContainer>
              </Fade>
            )}
            {!isUser && !item.isCachedData && !feedbackGiven && lastMessage && (
              <div onClick={() => setCurrentPromptText(item.userPrompt || '')}>
                <ChatPageFeedbackIcons
                  currentPromptText={currentPromptText}
                  openFeedbackModal={setIsFeedbackModalOpen}
                  setFeedbackGiven={setFeedbackGiven}
                />
              </div>
            )}
          </MessageRow>
        );
      })}
      <AddPromptModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        currentPromptText={currentPromptText}
      />
      <FeedBackModal
        isOpen={isFeedbackModalOpen}
        setIsOpen={setIsFeedbackModalOpen}
        currentPromptText={currentPromptText}
        setFeedbackGiven={setFeedbackGiven}
      />
    </Container>
  );
};

export default MessageRecycler;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-row: 1 / span 7;
`;

const RelaxedSpanButton = styled.span`
  color: ${({ theme }) => theme.title};
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const RelaxedText = styled.span``;

const PromptDiv = styled.div`
  margin-bottom: 7px;
`;

export const StyledTitleLine = styled.span`
  display: block;
  margin-bottom: 8px;
`;
const StyledLine = styled.span`
  margin-bottom: 2px;
  font-style: italic;
`;
const StyledPrompt = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-left: 10px;
  color: ${({ theme }) =>
    theme.promptText}; // You can select here the prompt text color
`;

export const MessageRow = styled.div<{
  isUser: boolean;
  shouldOverflow: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;

  /*  overflow-y allows the background to stop being scrollable when modal is Open */
  overflow-y: ${({ shouldOverflow }) => (shouldOverflow ? 'hidden' : 'none')};

  align-items: ${({ isUser }) => (isUser ? 'end' : 'start')};
  gap: 10px;
  width: 100%;
  border-radius: 10px;
`;

export const QueryContainer = styled.div<{
  isUser?: boolean | undefined;
}>`
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: ${({ isUser }) =>
    isUser ? '0.5rem 0px 0.5rem 0.5rem' : '0 0.5rem 0.5rem 0.5rem'};
  margin: 0;

  .fade-container {
    color: ${(props) => props.theme.text};
  }
`;

const TruckPromptContainer = styled(QueryContainer)`
  flex-direction: row;
  position: relative;
`;

import { IRouteMessageItem } from '../../Models/RoutePlannerModel';

export const addRouteMessage = (
  setMessages: React.Dispatch<React.SetStateAction<IRouteMessageItem[]>>,
  baseMessage: Partial<IRouteMessageItem>
) => {
  setMessages((prevState) => [
    ...prevState,
    { ...baseMessage } as IRouteMessageItem,
  ]);
};

export const handleNoRouteResponse = (
  setRouteMessages: React.Dispatch<React.SetStateAction<IRouteMessageItem[]>>,
  response: any
) => {
  console.error('Failed to fetch message response:', response);
  addRouteMessage(setRouteMessages, {
    isUser: false,
    title:
      response && response.data
        ? response.data.message
        : 'There was an issue retrieving your messages. Please try again later.',
  });
};

export const handleUnexpectedRouteResultStructure = (
  setRouteMessages: React.Dispatch<React.SetStateAction<IRouteMessageItem[]>>,
  result: any
) => {
  console.warn('Unexpected message structure:', result);
  addRouteMessage(setRouteMessages, {
    isUser: false,
    title: 'A response from the server could not be processed.',
  });
};

import { MessageItem } from '../../../Models/LoadModel';

export const addMessage = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  baseMessage: Partial<MessageItem>
) => {
  setMessages((prevState) => [...prevState, { ...baseMessage } as MessageItem]);
};

export const handleNoProviders = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>
) => {
  console.error('User not authorized with any boards');
  addMessage(setMessages, {
    isUser: false,
    title:
      'You are not authorized with any providers. Please log in to a provider to get results.',
  });
};

export const handleNoResponse = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  response: any
) => {
  console.error('Failed to fetch message response:', response);
  if (response?.status === 404) {
    // Handle 404 specifically
    addMessage(setMessages, {
      isUser: false,
      title: 'No results were found for your query. Pls try a different query or schedule a query using LoadScout',
    });
  } else {
    // Handle other errors
    addMessage(setMessages, {
      isUser: false,
      title:
        'We\'re currently experiencing difficulties processing your request. Our team has been notified and is working to get things back on track. Please try a different request in the meantime.',
    });
  }
};

export const handleMultipleDataError = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  responseData: any
) => {
  console.error(
    'Invalid data structure with Multiple Prompts modification:',
    responseData
  );
  addMessage(setMessages, {
    isUser: false,
    title: 'A response from the server could not be processed.',
  });
};

export const handleUnexpectedResultStructure = (
  setMessages: React.Dispatch<React.SetStateAction<MessageItem[]>>,
  result: any
) => {
  console.warn('Unexpected message structure:', result);
  addMessage(setMessages, {
    isUser: false,
    title: 'A response from the server could not be processed.',
  });
};
